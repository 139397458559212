import { gql } from "@apollo/client";

export const GET_ALL_CARS = gql`
  query getAllCars($tokenGet: String!, $branchOffice: Int, $active: Boolean) {
    getCars(token: $tokenGet, branchOffice: $branchOffice, active: $active) {
      id
      licence_plate
      driver_firstname
      driver_lastname
      driver_email
      driver_phone
      sendSms
      note
      type
      active
      branchOffice {
        company
        id
      }
      carRenter {
        company
        id
      }
      carOwner {
        company
        id
      }
      storage_status
      storage {
        id
        number
        tyre_brand
        season
        note
        qty
        disk_type
        date
        speedometer
        thread
      }
      services {
        id
        date
      }
    }
  }
`;
export const GET_ALL_USERS = gql`
  query getAllUsers($tokenGet: String!) {
    getUserById(token: $tokenGet) {
      id
      firstname
      lastname
      branchOffice {
        id
        firstname
        lastname
        company
        street
        city
        zip
        phone
        email
        ic
        dic
        ic_dph
      }
      email
      active
    }
  }
`;
export const GET_CAR_BY_ID = gql`
  query getCarById($carId: ID, $tokenGet: String!) {
    getCars(id: $carId, token: $tokenGet) {
      id
      licence_plate
      driver_firstname
      driver_lastname
      driver_email
      driver_phone
      sendSms
      note
      type
      active
      branchOffice {
        company
        id
      }
      carRenter {
        company
      }
      carOwner {
        company
      }
      storage_status
      storage {
        id
        number
        tyre_brand
        size
        speed
        capacity
        thread
        season
        qty
        focus
        note
        disk_type
        date
        speedometer
      }
      reservations {
        id
        from_time
        date
        note
      }
      services {
        id
        date
        status
      }
    }
  }
`;
export const GET_OWNER_CARS = gql`
  query getCarById($carOwnerId: ID, $tokenGet: String!) {
    getCars(carOwnerId: $carOwnerId, token: $tokenGet) {
      id
      licence_plate
      driver_firstname
      driver_lastname
      driver_email
      driver_phone
      sendSms
      note
      active
      branchOffice {
        company
        id
      }
      carRenter {
        company
      }
      carOwner {
        company
      }
      storage_status
      storage {
        id
        number
        tyre_brand
        qty
        thread
        season
        disk_type
        speedometer
      }
      reservations {
        id
        from_time
        date
        note
      }
    }
  }
`;
export const GET_BRANCH_OFICCES = gql`
  query getBranchOficces($tokenGet: String!) {
    getBranchOficces(token: $tokenGet) {
      id
      active
      firstname
      lastname
      company
      street
      city
      zip
      phone
      email
      ic
      dic
      ic_dph
      devices {
        id
        first_reservation
        last_reservation
      }
    }
  }
`;
export const GET_CAR_OWNERS = gql`
  query getCarOwners($tokenGet: String!) {
    getCarOwners(token: $tokenGet) {
      id
      active
      firstname
      lastname
      company
      street
      city
      zip
      phone
      email
      ic
      dic
      ic_dph
      reservation_emails
      invoice_emails
      invoice_maturity
      bonus
    }
  }
`;
export const GET_CAR_RENTERS = gql`
  query getCarRenters($tokenGet: String!) {
    getCarRenters(token: $tokenGet) {
      id
      active
      firstname
      lastname
      company
      street
      city
      zip
      phone
      email
      ic
      dic
      ic_dph
      reservation_emails
    }
  }
`;

export const GET_RENTER_CARS = gql`
  query getCarById($carRenterId: ID, $tokenGet: String!) {
    getCars(carRenterId: $carRenterId, token: $tokenGet) {
      id
      licence_plate
      driver_firstname
      driver_lastname
      driver_email
      driver_phone
      sendSms
      note
      active
      branchOffice {
        company
        id
      }
      carRenter {
        company
      }
      carOwner {
        company
      }
      storage_status
      storage {
        id
        number
        tyre_brand
        qty
        thread
        season
        disk_type
        speedometer
      }
      reservations {
        id
        from_time
        date
        note
      }
    }
  }
`;
export const GET_RESERVATIONS = gql`
  query getReservations($tokenGet: String!, $branchOfficeId: ID!) {
    getReservations(token: $tokenGet, branchOfficeId: $branchOfficeId) {
      id
      number
      from_time
      to_time
      status
      date
      pickup
      note
      car {
        id
        licence_plate
        driver_firstname
        driver_lastname
        driver_email
        driver_phone
        note
        sendSms
        carOwner {
          id
          company
        }
        carRenter {
          id
          company
        }
        storage {
          id
          number
        }
      }
      device {
        id
        first_reservation
        last_reservation
        branchOffice {
          id
          lastname
          firstname
        }
      }
    }
  }
`;
export const GET_DEVICES_BY_BRANCHOFFICE_ID = gql`
  query getDevicesByBranchOfficeId($tokenGet: String!, $branchOffice: ID!) {
    getDevicesByBranchOfficeId(token: $tokenGet, branchOffice: $branchOffice) {
      id
      first_reservation
      last_reservation
    }
  }
`;
export const GET_RESERVATIONS_BY_DATE = gql`
  query getReservationsByDate(
    $tokenGet: String!
    $fromDate: String!
    $toDate: String!
  ) {
    getReservationsByDate(
      token: $tokenGet
      fromDate: $fromDate
      toDate: $toDate
    ) {
      id
      number
      from_time
      to_time
      status
      date
      pickup
      note
      car {
        id
        licence_plate
        branchOffice {
          id
          lastname
          firstname
        }
      }
      device {
        id
        branchOffice {
          id
          lastname
          firstname
        }
        first_reservation
        last_reservation
      }
    }
  }
`;
export const GET_PRODUCTS = gql`
  query getProducts($tokenGet: String!) {
    getProducts(token: $tokenGet) {
      id
      name
      price_purchase
      price_sale
      qty
      tax
      type
      code
      stockMove {
        date
        type
        qty
      }
      priceLists {
        id
        price_sale
        carOwner {
          id
          company
        }
      }
    }
  }
`;

export const GET_TAXES = gql`
  query getTaxes($tokenGet: String!) {
    getTaxes(token: $tokenGet) {
      id
      name
      value
    }
  }
`;

export const GET_PRICELIST = gql`
  query getPricelist($carOwner: ID, $tokenGet: String!) {
    getPricelist(carOwner: $carOwner, token: $tokenGet) {
      id
      price_sale
      carOwner {
        company
      }
      product {
        name
      }
    }
  }
`;
export const GET_SERVICE_ACTIONS = gql`
  query getServiceActions(
    $tokenGet: String!
    $status: String
    $id: ID
    $offset: Int
    $limit: Int
    $search: String
  ) {
    getServiceActions(
      token: $tokenGet
      status: $status
      id: $id
      offset: $offset
      limit: $limit
      search: $search
    ) {
      records {
        id
        status
        date
        mileage
        note
        authorization_code
        email_send
        invoiced
        invoiceSendStatus
        invoiceSendStatusEcon
        invoice_number
        payment_type
        invoice_due_date
        exportBlStatus
        exportBlDate
        invoice_issue_date
        total
        branchOffice {
          id
          company
          street
          city
          zip
          phone
          email
          ic
          dic
          ic_dph
        }
        car {
          id
          licence_plate
          driver_firstname
          driver_lastname
          driver_phone
          type
          storage {
            id
            number
            tyre_brand
            size
            speed
            capacity
            thread
            season
            qty
            focus
            note
            disk_type
            date
            speedometer
          }
          carRenter {
            id
            company
          }
        }
        carOwner {
          id
          company
          street
          city
          zip
          phone
          email
          ic
          dic
          ic_dph
          bonus
          invoice_emails
        }
        items {
          id
          product_name
          qty
          price_purchase
          price_sale
          price_total
          tax
          price_sale_tax
          price_total_tax
          note
        }
      }
      total
    }
  }
`;
export const GET_STORAGE_LIST = gql`
  query getStorageList($id: ID, $tokenGet: String!) {
    getStorageList(id: $id, token: $tokenGet) {
      id
      number
      tyre_brand
      size
      speed
      qty
      capacity
      thread
      season
      focus
      note
      date
      disk_type
      speedometer
      car {
        id
        licence_plate
        driver_firstname
        driver_lastname
        carOwner {
          id
          company
        }
        carRenter {
          id
          company
        }
        branchOffice {
          id
          company
        }
      }
      reservation {
        id
        date
      }
    }
  }
`;
export const GET_BRANCH_OFFICES = gql`
  query getBranchOficces($tokenGet: String!) {
    getBranchOficces(token: $tokenGet) {
      id
      active
      firstname
      lastname
      company
      street
      city
      zip
      phone
      email
      send_reservation_email
      ic
      dic
      ic_dph
      note
      devices {
        id
        first_reservation
        last_reservation
      }
      cars {
        id
        licence_plate
        driver_firstname
        driver_lastname
        driver_phone
        driver_email
        note
        sendSms
        active
      }
    }
  }
`;
