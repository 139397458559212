import React, { useState } from "react";
import { Row, Col, Form, Input, Spin } from "antd";
import { Link, useHistory } from "react-router-dom";
// import { RecordFormWrapper } from './Style';
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Button } from "../../../components/buttons/buttons";
import { Main, BasicFormWrapper } from "../../styled";

import { useQuery, useMutation } from "@apollo/client";
import { ADD_CAR } from "../../../graphql/AdminMutations";
import { GET_BRANCH_OFICCES } from "../../../graphql/AdminQueries";
import { SelectCompany } from "../../../components/customSelect/selectCompany";

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
};

const AddNewCar = () => {
  const [form] = Form.useForm();

  const history = useHistory();

  const [state, setState] = useState({
    isLoading: false,
  });

  // create new car
  const [triggerCreateCar] = useMutation(ADD_CAR, {
    variables: graphqlVariables,
  });
  const [loadData, setLoadData] = useState(false);
  const [branchOficcesData, setBranchOficcesData] = useState([]);

  const { data: branchOfices, loading: loading, error: error } = useQuery(
    GET_BRANCH_OFICCES,
    {
      variables: graphqlVariables,
      skip: !loadData,
    }
  );
  if (branchOfices) {
    const companies =
      branchOfices?.getBranchOficces && branchOfices?.getBranchOficces[0]
        ? branchOfices.getBranchOficces
        : [];
    setBranchOficcesData(companies);
  }
  const { isLoading } = state;

  const handleSubmit = (values) => {
    setState({
      ...state,
      isLoading: true,
    });

    // console.log('values: ', values)

    const variables = {
      ...graphqlVariables,
      licencePlate: values.licence_plate,
      firstname: values.firstname,
      lastname: values.lastname,
      phone: "phone",
      // "phone": values.phone,
      email: "email",
      // "email": values.email,
      branchOffice: parseInt(values.company),
      carOwner: parseInt(values.owner),
      carRenter: parseInt(values.renter),
    };

    triggerCreateCar({ variables }).then(({ data }) => {
      const carResponse = data?.createCar;
      // console.log('GRAPHQL - ADD CAR response: ', carResponse)

      if (carResponse?.sucessful) {
        setState({
          ...state,
          isLoading: false,
        });

        form.resetFields();

        // redirect to grid/overview
        history.push("/admin/car");
      }
    });
  };

  return (
    <>
      <PageHeader
        buttons={[
          <Button className="btn-add_new" size="default" key="1" type="primary">
            <Link to="/admin/car">View All</Link>
            {/* <Link to="/car">View All</Link> */}
          </Button>,
        ]}
        ghost
        title="Add New"
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {/* <RecordFormWrapper> */}
            <Cards headless>
              <Row justify="center">
                <Col xl={10} md={16} xs={24}>
                  <BasicFormWrapper>
                    <Form
                      className="add-record-form"
                      style={{ width: "100%" }}
                      layout="vertical"
                      form={form}
                      name="addnew"
                      onFinish={handleSubmit}
                    >
                      <Form.Item
                        label="Licence plate"
                        name="licence_plate"
                        rules={[
                          {
                            message: "Please input licence plate!",
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="SPZ" />
                      </Form.Item>

                      <Form.Item
                        label="Driver firstname"
                        name="firstname"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>

                      <Form.Item
                        label="Driver lastname"
                        name="lastname"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>

                      <Form.Item name="company" label="Company">
                        <SelectCompany
                          companies={branchOficcesData}
                          form={Form}
                        />
                      </Form.Item>

                      <Form.Item name="owner" label="Owner">
                        <Input placeholder="Dropdown" />
                      </Form.Item>

                      <Form.Item name="renter" label="Renter">
                        <Input placeholder="Dropdown" />
                      </Form.Item>
                      <div className="record-form-actions text-right">
                        <Button size="default" htmlType="Save" type="primary">
                          {isLoading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </BasicFormWrapper>
                </Col>
              </Row>
            </Cards>
            {/* </RecordFormWrapper> */}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default AddNewCar;
