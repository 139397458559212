import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Tabs } from "antd";
import { Main } from "../../styled";
import { PageHeader } from "../../../components/page-headers/page-headers";

import { useTranslation } from "react-i18next";
import InfoFormLeft from "./infoFormLeft";

import { useSelector, useDispatch } from "react-redux";
import { GetGraphQlData } from "../actions/dataQueries";

import { setAllServices } from "../../../redux/services/actionCreator";
import InfoFormRight from "./infoFormRight";
import List from "./items/list";

import { getApplicationSettings } from "../../../utils/common";
import { CarStorage } from "../../car/overview/tabs/carStorage";

const { TabPane } = Tabs;

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
  offset: 0,
  limit: 0,
  status: undefined,
  search: undefined,
};

const ActionDetail = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const actionId = id.toString().padStart(7, "0");
  graphqlVariables.id = id;

  const [loadData, setLoadData] = useState(false);
  const [canDisplay, setCanDisplay] = useState(false);
  const [serviceItems, setServiceItems] = useState();
  const [carOwnerId, setCarOwnerId] = useState();
  const [carOwnerBonus, setCarOwnerBonus] = useState();
  const [statusChange, setStatusChange] = useState();
  const [invoiceData, setInvoiceData] = useState();

  const serviceData = useSelector((state) => state?.services?.allServices[0]);
  const selectedCar = { storage: serviceData?.car?.storage };

  useEffect(() => {
    setLoadData(true);
  }, []);

  const graphQlData = GetGraphQlData(graphqlVariables, loadData);
  const servicesData = graphQlData[0]?.data?.getServiceActions?.records;

  if (servicesData) {
    setLoadData(false);
    setCanDisplay(true);
    setCarOwnerId(servicesData[0].carOwner.id);
    setCarOwnerBonus(servicesData[0].carOwner.bonus);
    dispatch(setAllServices(servicesData));
    setStatusChange(servicesData[0].status);
  }

  useEffect(() => {
    setServiceItems(serviceData?.items);
  }, [servicesData]);

  const settings = getApplicationSettings("invoicing", loadData);
  useEffect(() => {
    if (settings) {
      console.log("settings:: ", settings);
      setInvoiceData(settings);
    }
  }, [settings]);
  return (
    canDisplay && (
      <>
        <PageHeader
          ghost
          title={t("serviceActions.detail.title") + " " + actionId}
        />
        <Main>
          <Row gutter={25}>
            <Col xs={24}>
              <Tabs>
                <TabPane
                  tab={t("serviceActions.detail.tabs.tab2Title")}
                  key="tab2"
                >
                  <InfoFormRight
                    t={t}
                    data={serviceData}
                    graphqlVariables={graphqlVariables}
                    setStatusChange={setStatusChange}
                    invoiceData={invoiceData}
                  />
                </TabPane>
                <TabPane
                  tab={t("serviceActions.detail.tabs.tab1Title")}
                  key="tab1"
                >
                  <InfoFormLeft t={t} data={serviceData} />
                </TabPane>
                <TabPane
                  tab={t("serviceActions.detail.tabs.tab3Title")}
                  key="tab3"
                >
                  <CarStorage t={t} carData={selectedCar} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col xs={24}>
              <List
                t={t}
                data={serviceData}
                inputData={serviceItems}
                graphqlVariables={graphqlVariables}
                carOwnerId={carOwnerId}
                carOwnerBonus={carOwnerBonus}
                serviceActionId={id}
                statusChange={statusChange}
              />
            </Col>
          </Row>
        </Main>
      </>
    )
  );
};

export default ActionDetail;
