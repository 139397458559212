import React, { useState, useEffect } from "react";
import { Tabs } from "antd";

import { Modal } from "../../../../components/modals/antd-modals";
import { Tab1 } from "./tab1";

import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

import { GetBranchOffices } from "../../dataQueries";

import { UPDATE_BRANCH_OFFICE } from "../../../../graphql/AdminMutations";

import displayNotification from "../../../reservation/admin/notification/notification";
import { Tab2 } from "./tab2";
import { updateBranchOfficce } from "../../../../redux/branchOfficces/actionCreator";

const { TabPane } = Tabs;

const ModalForm = (props) => {
  const {
    t,
    tableData,
    data,
    formReadonly,
    form,
    selectedItem,
    setState,
    showModal,
  } = props;

  const [loadData, setLoadData] = useState(false);
  const selectedItemId = selectedItem.id;
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  let branchOfficceNum = 0;

  useEffect(() => {
    setLoadData(true);
  }, []);

  const entityVariables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    branchOfficeId: selectedItemId,
  };

  const graphQlData = GetBranchOffices(entityVariables, loadData);
  // const branchOfficceData = data?.inputData.find(
  //   (item) => Number(item.id) === Number(selectedItemId)
  // );
  let branchOfficceData = graphQlData?.data?.getBranchOficces;
  if (graphQlData) {
    branchOfficceData = graphQlData[0].data.getBranchOficces;
  }

  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
    id: selectedItemId,
  };

  const [triggerUpdateBranchOffice] = useMutation(UPDATE_BRANCH_OFFICE, {
    variables: graphqlVariables,
  });

  const onCancel = () => {
    setState({
      editVisible: false,
      update: {},
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleEditOk = (data) => {
    onCancel();

    const updatedData = tableData.map((company) => {
      if (company.id === selectedItemId) {
        const updatedData = { ...company };
        updatedData.active = data.active ? data.active : 1;
        updatedData.firstname = data.firstname;
        updatedData.lastname = data.lastname;
        updatedData.company = data.company;
        updatedData.street = data.street;
        updatedData.city = data.city;
        updatedData.zip = data.zip;
        updatedData.phone = data.phone;
        updatedData.email = data.email;
        updatedData.send_reservation_email = data.send_reservation_email;
        updatedData.note = data.note;
        updatedData.ic = data.ic;
        updatedData.dic = data.dic;
        updatedData.ic_dph = data.ic_dph;
        return updatedData;
      } else {
        return company;
      }
    });
    // dispatch(updateCompany(updatedData))

    const variables = {
      ...graphqlVariables,
      active: data.active ? data.active : 1,
      firstname: data.firstname,
      lastname: data.lastname,
      company: data.company,
      zip: data.zip,
      street: data.street,
      city: data.city,
      phone: data.phone,
      email: data.email,
      ic: data.ic,
      dic: data.dic,
      ic_dph: data.ic_dph,
      send_reservation_email: data.send_reservation_email,
      note: data.note,
    };

    triggerUpdateBranchOffice({ variables }).then(({ data }) => {
      let type = "success";
      const message = t("backend.notifications.update");
      let desc = t("backend.notifications.success.recordUpdate");
      if (data.updateBranchOffice !== undefined) {
        dispatch(updateBranchOfficce(updatedData));
      } else {
        type = "error";
        desc = t("backend.notifications.error.recordUpdate");
      }

      displayNotification(type, message, desc);
      setTimeout(function() {
        location.reload();
      }, 3000);
    });
  };
  return (
    <Modal
      type="primary"
      title={selectedItem.company}
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.8}
    >
      <Tabs>
        <TabPane tab={t("carOwners.modal.tabs.tab1.title")} key="tab1">
          <Tab1
            t={t}
            formReadonly={formReadonly}
            form={form}
            handleEditOk={handleEditOk}
          />
        </TabPane>
        {formReadonly && (
          <TabPane tab={t("carOwners.modal.tabs.tab2.title")} key="tab2">
            <Tab2
              t={t}
              selectedItem={selectedItem}
              branchOfficceData={branchOfficceData}
            />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export { ModalForm };
