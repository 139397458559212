import React from "react";
import { Form, Input, Row, Col, Checkbox } from "antd";
import { BasicFormWrapper } from "../../../styled";

import { Modal } from "../../../../components/modals/antd-modals";

import { useDispatch } from "react-redux";

import { useMutation } from "@apollo/client";

import { Button } from "../../../../components/buttons/buttons";

import { SelectCompany } from "../../../../components/customSelect/selectCompany";
import { SelectCarOwner } from "../../../../components/customSelect/selectOwner";
import { SelectCarRenter } from "../../../../components/customSelect/selectRenter";

import { updateCar } from "../../../../redux/cars/actionCreator";
import { UPDATE_CAR } from "../../../../graphql/AdminMutations";

import { Tabs } from "antd";

import { CarReservations } from "./carReservations";
import { QRCodeImage } from "./qrCode";
import { CarStorage } from "./carStorage";

import { useQuery } from "@apollo/client";

import { GET_CAR_BY_ID } from "../../../../graphql/AdminQueries";
import { CarServiceActions } from "./carServiceActions";

const { TabPane } = Tabs;

const CarDetailModalForm = (props) => {
  const {
    t,
    tableData,
    data,
    formReadonly,
    form,
    graphqlVariables,
    selectedCar,
    setState,
    showModal,
  } = props;

  // fetch car data from backend

  const variables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    carId: selectedCar.id,
  };

  // fetch car data
  const { data: carData } = useQuery(GET_CAR_BY_ID, {
    variables: variables,
    skip: false,
  });
  const selectedCarData =
    carData?.getCars && carData?.getCars[0] ? carData.getCars[0] : {};

  const { branchOficcesData, carOwnersData, carRentersData } = data;

  const dispatch = useDispatch();

  const onCancel = () => {
    setState({
      editVisible: false,
      update: {},
    });
  };
  const handleCancel = () => {
    onCancel();
  };
  graphqlVariables.id = selectedCar.id;
  const [triggerUpdateCarInfo] = useMutation(UPDATE_CAR, {
    variables: graphqlVariables,
  });

  const handleEditOk = (values) => {
    onCancel();
    const updatedCars = tableData.map((car) => {
      if (car.id === selectedCar.id) {
        const company = !isNaN(values.company)
          ? branchOficcesData.find(({ id }) => id === values.company).company
          : values.company;
        const owner = !isNaN(values.owner)
          ? carOwnersData.find(({ id }) => id === values.owner).company
          : values.owner;
        const renter = !isNaN(values.renter)
          ? carRentersData.find(({ id }) => id === values.renter).company
          : values.renter;

        const updatedCar = { ...car };
        updatedCar.licence_plate = values.licence_plate;
        updatedCar.driver_email = values.email;
        updatedCar.driver_phone = values.phone;
        updatedCar.driver_firstname = values.firstname;
        updatedCar.driver_lastname = values.lastname;
        updatedCar.branchOffice = { company: company };
        updatedCar.carOwner = { company: owner };
        updatedCar.carRenter = { company: renter };
        updatedCar.sendSms = values.sendSms;
        updatedCar.note = values.note;
        updatedCar.type = values.type;
        return updatedCar;
      } else {
        return car;
      }
    });

    dispatch(updateCar(updatedCars));

    const company = isNaN(values.company)
      ? branchOficcesData.find(({ company }) => company === values.company).id
      : values.company;
    const owner = isNaN(values.owner)
      ? carOwnersData.find(({ company }) => company === values.owner).id
      : values.owner;
    const renter = isNaN(values.renter)
      ? carRentersData.find(({ company }) => company === values.renter).id
      : values.renter;

    const variables = {
      ...graphqlVariables,
      carId: parseInt(updatedCars[0].id),
      licencePlate: values.licence_plate,
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone,
      email: values.email,
      branchOffice: parseInt(company),
      carOwner: parseInt(owner),
      carRenter: parseInt(renter),
      sendSms: values.sendSms || false,
      type: values.type || "",
      status: true,
      note: values.note || "",
    };

    triggerUpdateCarInfo({ variables }).then(({ data }) => {
      dispatch(updateCar(updatedCars));
    });
  };

  return (
    <Modal
      type="primary"
      title={t("car.carInformation")}
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
      width="100%"
    >
      <Tabs>
        <TabPane tab={t("car.tabs.tab1Title")} key="tab1">
          <BasicFormWrapper>
            <Form form={form} name="contactEdit" onFinish={handleEditOk}>
              <Row gutter={15}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={t("car.licencePlate")}
                    name="licence_plate"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("car.licencePlate")}
                      disabled={formReadonly}
                    />
                  </Form.Item>
                  <Form.Item
                    name="company"
                    label={t("car.company")}
                    rules={[{ required: true }]}
                  >
                    <SelectCompany
                      data={branchOficcesData}
                      disabled={formReadonly}
                    />
                  </Form.Item>

                  <Form.Item
                    name="owner"
                    label={t("car.owner")}
                    rules={[{ required: true }]}
                  >
                    <SelectCarOwner
                      data={carOwnersData}
                      disabled={formReadonly}
                    />
                  </Form.Item>

                  <Form.Item
                    name="renter"
                    label={t("car.renter")}
                    rules={[{ required: true }]}
                  >
                    <SelectCarRenter
                      data={carRentersData}
                      disabled={formReadonly}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("car.sendSms")}
                    name="sendSms"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={formReadonly} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={t("car.driverFirstname")}
                    name="firstname"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="" disabled={formReadonly} />
                  </Form.Item>

                  <Form.Item
                    label={t("car.driverLastname")}
                    name="lastname"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="" disabled={formReadonly} />
                  </Form.Item>
                  <Form.Item
                    label={t("car.driverEmail")}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: t("car.validation.wrongEmail"),
                      },
                      {
                        required: true,
                        message: t("car.validation.email"),
                      },
                    ]}
                  >
                    <Input placeholder="" disabled={formReadonly} />
                  </Form.Item>
                  <Form.Item
                    label={t("car.driverPhone")}
                    name="phone"
                    rules={[
                      {
                        autoComplete: "off",
                      },
                      // ({ getFieldValue }) => ({
                      //   validator (_, value) {
                      //     const pattern = '^[+]421[0-9]{9}$'
                      //     if (value.match(pattern)) {
                      //       return Promise.resolve()
                      //     }
                      //     return Promise.reject(t('car.validation.phone'))
                      //   }
                      // })
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder="+421xxxxxx"
                      autoComplete="off"
                      disabled={formReadonly}
                    />
                  </Form.Item>
                  <Form.Item label={t("car.type")} name="type">
                    <Input placeholder="" disabled={formReadonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item label={t("car.note")} name="note">
                    <Input placeholder="" disabled={formReadonly} />
                  </Form.Item>
                </Col>
              </Row>
              <>
                {!formReadonly && (
                  <Row gutter={15}>
                    <Col md={24} sm={24} xs={24} style={{ marginTop: "20px" }}>
                      <Button
                        htmlType="submit"
                        size="default"
                        type="primary"
                        key="submit"
                      >
                        {t("common.updateRecord")}
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            </Form>
          </BasicFormWrapper>
        </TabPane>
        {formReadonly && (
          <>
            <TabPane tab={t("car.tabs.tab2.title")} key="tab2">
              <CarReservations t={t} carData={selectedCarData} />
            </TabPane>
            <TabPane tab={t("car.tabs.tab3.title")} key="tab3">
              <QRCodeImage carData={selectedCarData} />
            </TabPane>
            <TabPane tab={t("car.tabs.tab4.title")} key="tab4">
              <CarStorage t={t} carData={selectedCarData} />
            </TabPane>
            <TabPane tab={t("car.tabs.tab5.title")} key="tab5">
              <CarServiceActions t={t} carData={selectedCarData} />
            </TabPane>
          </>
        )}
      </Tabs>
    </Modal>
  );
};

export { CarDetailModalForm };
