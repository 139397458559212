import { Table } from "antd";
import React from "react";

const CarStorage = (props) => {
  const { t, carData } = props;
  const { storage } = carData;
  const columns = getColumns(t);

  // Create a new array for the data
  const data =
    storage && Array.isArray(storage)
      ? [...storage]
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((item) => {
            const key = item.id;
            const link = "/admin/service/warehouse-item/" + key;
            let itemDate = "";
            if (item.date === "1899-11-30") {
              itemDate = "";
            } else {
              itemDate = new Date(item.date).toLocaleDateString("sk-SK");
            }
            return {
              date: itemDate,
              key: (
                <a key={item.id} href={link}>
                  {key}
                </a>
              ),
              number: item.number,
              tyreBrand: item.tyre_brand,
              qty: item.qty,
              thread: item.thread,
              season: item.season,
              diskType: item.disk_type,
              speedometer: item.speedometer,
            };
          })
      : [];

  return (
    <Table columns={columns} dataSource={data} key={(record) => record.uid} />
  );
};

const getColumns = (t) => {
  const keys = [
    "key",
    "date",
    "number",
    "tyreBrand",
    "qty",
    "thread",
    "season",
    "diskType",
    "speedometer",
  ];
  let columns = [];
  keys.forEach((item) => {
    columns.push({
      title: t("car.tabs.tab4.grid." + item),
      dataIndex: item,
      key: item,
    });
  });
  return columns;
};

export { CarStorage };
