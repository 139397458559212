import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Spin, Checkbox } from "antd";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, CardToolbox } from "../../styled";

import { Button } from "../../../components/buttons/buttons";
import { GetGraphQlData } from "./dataQueries";

import { useTranslation } from "react-i18next";
import { AutoComplete } from "../../../components/autoComplete/autoComplete";

import {
  setAllServices,
  setTableFiler,
} from "../../../redux/services/actionCreator";

import List from "./list";

import { useDispatch, useSelector } from "react-redux";
import NewRecordModal from "./newRecordModal";
import sendBusinessLeaseInvoices from "../../../Api/invoice";
import displayNotification from "../../reservation/admin/notification/notification";

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
};

const Actions = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const tableData = useSelector((state) => state?.services?.allServices);
  const listData = useSelector((state) => state?.services?.tableFilter);
  const [pageNumberToGrid, setPageNumberToGrid] = useState(1);
  const [loadingList, setLoadingList] = useState(true);
  const [total, setTotal] = useState(0);
  const [redirectToDetail, setRedirectToDetail] = useState(false);

  const [gridState, setGridState] = useState({
    addNewRecord: false,
  });

  const [branchOficcesData, setBranchOficcesData] = useState([]);
  const [carsData, setCarsData] = useState([]);
  const [value, setValue] = useState("");
  const [checkedStatus, setCheckedStatus] = useState();
  const [loadData, setLoadData] = useState(true);
  const limit = 20;

  const [searchText, setSearchText] = useState("");

  // Create query variables object that includes all dependencies
  const queryVariables = {
    ...graphqlVariables,
    offset: (pageNumberToGrid - 1) * limit,
    limit: searchText ? undefined : limit, // Remove limit when searching
    status: checkedStatus || undefined,
    search: searchText || undefined,
  };

  // Get the query result using the hook at component level
  const graphQlData = GetGraphQlData(queryVariables, loadData);
  const loading =
    graphQlData[0]?.loading ||
    graphQlData[1]?.loading ||
    graphQlData[2]?.loading;

  useEffect(() => {
    if (graphQlData) {
      const servicesData = graphQlData[0]?.data?.getServiceActions;
      const branchOffices = graphQlData[1]?.data?.getBranchOficces;
      const cars = graphQlData[2]?.data?.getCars;

      if (servicesData?.records && branchOffices && cars) {
        setBranchOficcesData(branchOffices);
        setCarsData(cars);
        setTotal(servicesData.total || 0);

        // Only update Redux state if the data has actually changed
        if (
          JSON.stringify(tableData) !== JSON.stringify(servicesData.records)
        ) {
          dispatch(setAllServices(servicesData.records));
          dispatch(setTableFiler(servicesData.records));
        }
        setLoadingList(false);
      }
    }
  }, [graphQlData, dispatch]);

  // Effect to handle page changes
  useEffect(() => {
    setLoadData(true);
  }, [pageNumberToGrid]);

  const handleChangePage = (pageNumber) => {
    setLoadingList(true);
    setPageNumberToGrid(pageNumber);
  };

  const handleSearch = (text) => {
    if (text.length < 3) {
      return;
    }
    setSearchText(text);
    setLoadingList(true);
    // Reset page number when searching
    setPageNumberToGrid(1);
    // Trigger new query with search text
    setLoadData(true);
  };

  const showNewRecordModal = () => {
    setGridState({
      ...gridState,
      addNewRecord: true,
    });
  };

  const exportBlAction = async () => {
    const confirm = window.confirm("Poslať hromadnú fakturáciu do BL?");
    if (confirm) {
      const result = await sendBusinessLeaseInvoices();
      let type = "success";
      let reload = true;
      const message = t("backend.notifications.update");
      let desc = "E-mail bol odoslaný";
      if (result.data.message === "no data") {
        desc = "Žiadne faktúry na odoslanie";
        reload = false;
      }
      if (result.status === false) {
        type = "error";
        desc = "E-mail nebol odoslaný, obráťte sa na podporu.";
        reload = false;
      }

      displayNotification(type, message, desc);
      if (reload) {
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      }
    }
  };
  const onCancel = () => {
    setGridState({
      ...gridState,
      addNewRecord: false,
    });
  };
  const handleCancel = () => {
    onCancel();
  };

  const onChangeCheckbox = (e) => {
    let data = tableData;
    setValue("");
    if (e.target.checked === true) {
      data = tableData.filter((item) => {
        return item.status === e.target.name;
      });
      setValue(e.target.value);
      setCheckedStatus(e.target.name);
    } else {
      setValue("");
      setCheckedStatus("");
    }
    dispatch(setTableFiler(data));
  };

  const serviceItems = [
    { value: "Otvorené", label: "open", name: "open" },
    { value: "Fakturované", label: "invoiced", name: "invoiced" },
    { value: "Schválené", label: "approved", name: "approved" },
    { value: "BL Export", label: "blExported", name: "blExported" },
    { value: "Odoslané", label: "sent", name: "sent" },
  ];
  let buttons = [
    <>
      <Button
        onClick={exportBlAction}
        className="btn-add_new"
        type="primary"
        key={Math.random()}
        style={{ marginLeft: "10px" }}
      >
        <Link to="#">BL Export</Link>
      </Button>
      <Button
        onClick={showNewRecordModal}
        className="btn-add_new"
        type="primary"
        key={Math.random()}
        style={{ marginLeft: "10px" }}
      >
        <Link to="#">{t("common.addRecord")}</Link>
      </Button>
    </>,
  ];
  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("backend.actions.title")}
          subTitle={
            <>
              <span className="title-counter">{t("carOwners.filter")} </span>
              <AutoComplete
                onSearch={handleSearch}
                // dataSource={notData}
                placeholder={t("carOwners.startWritting")}
                patterns
              />
              <Button
                onClick={() => window.location.reload()}
                className="btn-clear-search"
                style={{ marginLeft: "-100px" }}
                size="default"
                type="default"
              >
                Vymazať hľadanie
              </Button>

              {serviceItems.map((item) => {
                return (
                  <div key={item.name} className="col-sm-12 px-3 py-2">
                    <Checkbox
                      key={item.label}
                      onChange={onChangeCheckbox}
                      checked={item.value === value}
                      value={item.value}
                      name={item.name}
                    >
                      {item.value}
                    </Checkbox>
                  </div>
                );
              })}
            </>
          }
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingList && (
              <div className="spin">
                <Spin />
              </div>
            )}
            {!loadingList && (
              <List
                inputData={listData}
                graphqlVariables={graphqlVariables}
                checkedStatus={checkedStatus}
                total={total}
                sendPageNumberToGrid={handleChangePage}
                pageNumberToGrid={pageNumberToGrid}
                buttons={buttons}
              />
            )}
          </Col>
        </Row>
      </Main>
      <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        tableData={tableData}
        cars={carsData}
        branchOffices={branchOficcesData}
        setRedirectToDetail={setRedirectToDetail}
      />
      {/* <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        tableData={tableData}
        cars={carsData}
        branchOffices={branchOficcesData}
        setRedirectToDetail={setRedirectToDetail}
      /> */}
    </>
  );
};

export default Actions;
