import { useQuery } from "@apollo/client";
import { getItem } from "../../utility/localStorageControl";

import {
  GET_BRANCH_OFICCES,
  GET_CAR_OWNERS,
  GET_CAR_RENTERS,
  GET_ALL_CARS,
} from "../../graphql/AdminQueries";

const GetSelectData = (graphqlVariables, loadData) => {
  const branchOfices = useQuery(GET_BRANCH_OFICCES, {
    variables: graphqlVariables,
    skip: !loadData,
  });
  const carOwners = useQuery(GET_CAR_OWNERS, {
    variables: graphqlVariables,
    skip: !loadData,
  });

  const carRenters = useQuery(GET_CAR_RENTERS, {
    variables: graphqlVariables,
    skip: !loadData,
  });
  return [branchOfices, carOwners, carRenters];
};

const GetAllCars = (graphqlVariables, loadData) => {
  const user = getItem("user");
  if (user.role !== 1) {
    graphqlVariables.branchOffice = user?.branchOffice?.id;
  }
  const carsData = useQuery(GET_ALL_CARS, {
    variables: graphqlVariables,
    skip: !loadData,
  });
  console.log("carsData::: ", carsData);
  return [carsData];
};

export { GetSelectData, GetAllCars };
