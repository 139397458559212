import React from "react";
import { Form, Input, Row, Col, Checkbox } from "antd";
import { BasicFormWrapper } from "../../../styled";

import { Button } from "../../../../components/buttons/buttons";

import { Tabs } from "antd";

import { CarReservations } from "./carReservations";
import { QRCodeImage } from "./qrCode";
import { CarStorage } from "./carStorage";

import { CarServiceActions } from "./carServiceActions";
import { Cards } from "../../../../components/cards/frame/cards-frame";

const { TabPane } = Tabs;

const CarDetail = (props) => {
  const { t, data, form, selectedCar } = props;

  // fetch car data from backend
  const formReadonly = true;

  const initialValues = {
    licence_plate: selectedCar?.licence_plate,
    company: selectedCar?.branchOffice?.company,
    owner: selectedCar?.carOwner?.company,
    renter: selectedCar?.carRenter?.company,
    firstname: selectedCar?.driver_firstname,
    lastname: selectedCar?.driver_lastname,
    phone: selectedCar?.driver_phone,
    email: selectedCar?.driver_email,
    note: selectedCar?.note,
  };

  return (
    <>
      {initialValues && (
        <Cards headless>
          <Tabs>
            <TabPane tab={t("car.tabs.tab1Title")} key="tab1">
              <BasicFormWrapper>
                <Form form={form} initialValues={initialValues}>
                  <Row gutter={15}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={t("car.licencePlate")}
                        name="licence_plate"
                      >
                        {initialValues.licence_plate}
                      </Form.Item>
                      <Form.Item name="company" label={t("car.company")}>
                        {initialValues.company}
                      </Form.Item>

                      <Form.Item name="owner" label={t("car.owner")}>
                        {initialValues.owner}
                      </Form.Item>

                      <Form.Item name="renter" label={t("car.renter")}>
                        {initialValues.renter}
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={t("car.driverFirstname")}
                        name="firstname"
                      >
                        {initialValues.firstname}
                      </Form.Item>

                      <Form.Item
                        label={t("car.driverLastname")}
                        name="lastname"
                      >
                        {initialValues.lastname}
                      </Form.Item>
                      <Form.Item label={t("car.driverEmail")} name="email">
                        {initialValues.email}
                      </Form.Item>
                      <Form.Item label={t("car.driverPhone")} name="phone">
                        {initialValues.phone}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item label={t("car.note")} name="note">
                        {initialValues.note}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BasicFormWrapper>
            </TabPane>
            <TabPane tab={t("car.tabs.tab2.title")} key="tab2">
              <CarReservations t={t} carData={selectedCar} />
            </TabPane>
            <TabPane tab={t("car.tabs.tab3.title")} key="tab3">
              <QRCodeImage carData={selectedCar} />
            </TabPane>
            <TabPane tab={t("car.tabs.tab4.title")} key="tab4">
              <CarStorage t={t} carData={selectedCar} />
            </TabPane>
            <TabPane tab={t("car.tabs.tab5.title")} key="tab5">
              <CarServiceActions t={t} carData={selectedCar} />
            </TabPane>
          </Tabs>
        </Cards>
      )}
    </>
  );
};

export { CarDetail };
