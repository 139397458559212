import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CarsGrid from "../../container/car/grid";
import EditCar from "../../container/car/overview/EditCar";

const CarRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route
          exact
          path={`${path}/`}
          render={(props) => <CarsGrid {...props} active={false} />}
        />
        <Route exact path={`${path}/edit/:id`} component={EditCar} />
      </Suspense>
    </Switch>
  );
};

export default CarRoutes;
