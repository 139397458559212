import { Table } from "antd";
import React from "react";

const CarServiceActions = (props) => {
  const { t, carData } = props;
  const { services } = carData;
  const columns = getColumns(t);

  // Create a new array for the data with proper sorting
  const data =
    services && Array.isArray(services)
      ? [...services]
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((item) => {
            const key = item.id;
            const link = "/admin/service/action/" + key;
            return {
              date: (
                <a key={item.id} href={link}>
                  {new Date(item.date).toLocaleDateString("sk-SK")}
                </a>
              ),
              key: (
                <a key={item.id} href={link}>
                  {key}
                </a>
              ),
              status: (
                <a key={item.id} href={link}>
                  {t("serviceActions.tableList.statuses." + item.status)}
                </a>
              ),
            };
          })
      : [];

  return (
    <Table columns={columns} dataSource={data} key={(record) => record.uid} />
  );
};

const getColumns = (t) => {
  const keys = ["key", "date", "status"];
  let columns = [];
  keys.forEach((item) => {
    columns.push({
      title: t("car.tabs.tab5.grid." + item),
      dataIndex: item,
      key: item,
    });
  });
  return columns;
};

export { CarServiceActions };
