import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import { UserTableStyleWrapper } from "../../../pages/style";
import { TableWrapper } from "../../../styled";
import { setTableTab2Filer } from "../../../../redux/car_renters/actionCreator";
import CarsGrid from "../../../../container/car/grid";

import { useDispatch, useSelector } from "react-redux";

import { setAllCars } from "../../../../redux/cars/actionCreator";
import { AutoComplete } from "../../../../components/autoComplete/autoComplete";

const Tab2 = (props) => {
  const { t, branchOfficceData, selectedItem, onTitleUpdate } = props;
  const [searchOptions, setSearchOptions] = useState([]);
  const dispatch = useDispatch();

  const cars = selectedItem?.cars || [];

  useEffect(() => {
    // Update tab title with car count
    if (typeof onTitleUpdate === "function") {
      onTitleUpdate(`${t("car.cars")} (${cars.length})`);
    }
  }, [cars.length, onTitleUpdate, t]);

  useEffect(() => {
    dispatch(setAllCars(branchOfficceData));
    dispatch(setTableTab2Filer(branchOfficceData));
  }, [branchOfficceData]);

  const handleSearch = (searchText) => {
    if (!cars) return;

    // Update search options
    if (searchText) {
      const matchingOptions = cars
        .filter((car) => {
          const licencePlate = car?.licence_plate || "";
          const company = car?.branchOffice?.company || "";
          const owner = car?.carOwner?.company || "";
          const firstName = car?.driver_firstname || "";
          const lastName = car?.driver_lastname || "";

          const searchUpper = searchText.toUpperCase();
          return (
            licencePlate.toUpperCase().includes(searchUpper) ||
            company.toUpperCase().includes(searchUpper) ||
            owner.toUpperCase().includes(searchUpper) ||
            firstName.toUpperCase().includes(searchUpper) ||
            lastName.toUpperCase().includes(searchUpper)
          );
        })
        .map((car) => ({
          value: car.licence_plate,
          label: `${car.licence_plate} - ${car?.branchOffice?.company ||
            ""} (${car?.carOwner?.company || ""})`,
        }));

      setSearchOptions(matchingOptions);
    } else {
      setSearchOptions([]);
    }
  };

  return (
    <Cards headless>
      <div style={{ marginBottom: "20px" }}>
        <AutoComplete
          onSearch={handleSearch}
          placeholder={t("carOwners.startWritting")}
          width="100%"
          options={searchOptions}
        />
      </div>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <CarsGrid active={false} data={cars} t={t} />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  );
};

export { Tab2 };
