import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Form, Input, Checkbox } from "antd";

import { Link, useParams } from "react-router-dom";
import CarListTable from "./overview/CarsTable";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, CardToolbox, BasicFormWrapper } from "../styled";

import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { AddUser } from "../pages/style";

import { useMutation } from "@apollo/client";
import { ADD_CAR } from "../../graphql/AdminMutations";

import { Modal } from "../../components/modals/antd-modals";

// import { SelectCompany } from '../../components/customSelect/selectCompany'
import { SelectCompanyForNewCar } from "../../components/customSelect/selectCompanyForNewCar";
import { SelectCarOwner } from "../../components/customSelect/selectOwner";
import { SelectCarRenter } from "../../components/customSelect/selectRenter";

import { useDispatch, useSelector } from "react-redux";
import {
  setAllCars,
  setTableCars,
  addCar,
  setNewCarDataSelect,
} from "../../redux/cars/actionCreator";
import { GetSelectData, GetAllCars } from "./newCarDataQueries";

import { useTranslation } from "react-i18next";
import displayNotification from "../reservation/admin/notification/notification";

const CarList = ({ active }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.cars?.cars);

  const graphqlVariables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
    active: active,
  };

  const allData = useSelector((state) => state.cars?.allCars);

  const [state, setState] = useState({
    selectedRowKeys: 0,
    selectedRows: 0,
    addVisible: false,
  });

  const [form] = Form.useForm();

  const [loadData, setLoadData] = useState(false);
  const [branchOficcesData, setBranchOficcesData] = useState([]);
  const [carOwnersData, setCarOwnersData] = useState([]);
  const [carRentersData, setCarRentersData] = useState([]);
  const [loadingCars, setLoadingCars] = useState(true);

  const newCarSelectData = useSelector(
    (state) => state?.cars?.newCarSelectData
  );

  useEffect(() => {
    if (newCarSelectData) {
      setBranchOficcesData(newCarSelectData.branchOfices);
      setCarOwnersData(newCarSelectData.carOwners);
      setCarRentersData(newCarSelectData.carRenters);
    }
  }, [newCarSelectData]);

  useEffect(() => {
    setLoadData(true);
  }, []);

  const graphQlData = GetSelectData(graphqlVariables, loadData);
  const getAllCarsData = GetAllCars(graphqlVariables, loadData);
  const processedCarsData = getAllCarsData[0]?.data?.getCars;
  const branchOfices = graphQlData[0]?.data?.getBranchOficces;
  const carOwners = graphQlData[1]?.data?.getCarOwners;
  const carRenters = graphQlData[2]?.data?.getCarRenters;
  if (processedCarsData && branchOfices && carOwners && carRenters) {
    console.log("processedCarsData::: ", processedCarsData);
    dispatch(setAllCars(processedCarsData));
    dispatch(setTableCars(processedCarsData));
    setLoadingCars(getAllCarsData[0]?.loading);
    dispatch(setNewCarDataSelect({ branchOfices, carOwners, carRenters }));
    setLoadData(false);
  }

  // create new car
  const [triggerCreateCar] = useMutation(ADD_CAR, {
    variables: graphqlVariables,
  });

  const handleSearch = (searchText) => {
    const data = allData.filter(
      (item) =>
        item.licence_plate.toUpperCase().includes(searchText.toUpperCase()) ||
        item?.branchOffice?.company
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        item?.carOwner?.company
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        item?.carRenter?.company
          .toUpperCase()
          .includes(searchText.toUpperCase())
    );
    dispatch(setTableCars(data));
  };

  const showModal = () => {
    setState({
      ...state,
      addVisible: true,
    });
  };

  const onCancel = () => {
    setState({
      ...state,
      addVisible: false,
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = (values) => {
    onCancel();
    const variables = {
      ...graphqlVariables,
      licencePlate: values.licence_plate,
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone,
      email: values.email,
      note: values.note,
      sendSms: values.sendSms,
      type: values.type,
      branchOffice: parseInt(values.company),
      carOwner: parseInt(values.owner),
      carRenter: parseInt(values.renter),
    };

    triggerCreateCar({ variables }).then(({ data }) => {
      const carResponse = data?.createCar;
      let type = "success";
      const message = t("backend.notifications.update");
      let desc = t("backend.notifications.success.recordCreate");
      if (carResponse[0]) {
        dispatch(addCar(carResponse[0]));
      } else {
        type = "error";
        desc = t("backend.notifications.error.recordCreate");
      }

      displayNotification(type, message, desc);
      setTimeout(function() {
        location.reload();
      }, 3000);
    });
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("car.carList")}
          subTitle={
            <>
              {/* <span className="title-counter">{cars.length} Cars </span> */}
              <span className="title-counter">
                {tableData.length} {t("car.cars")}{" "}
              </span>
              <AutoComplete
                onSearch={handleSearch}
                // dataSource={notData}
                placeholder={t("car.licencePlate")}
                width="100%"
                patterns
              />
            </>
          }
          buttons={[
            <Button
              onClick={showModal}
              className="btn-add_new"
              size="default"
              type="primary"
              key="1"
            >
              {/* <Link to="/admin/users/add-user/info">+ Add New Car</Link> */}
              <Link to="#">{t("common.addRecord")}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingCars && (
              <div className="spin">
                <Spin />
              </div>
            )}
            {!loadingCars && (
              <CarListTable
                branchOficcesData={branchOficcesData}
                carOwnersData={carOwnersData}
                carRentersData={carRentersData}
                active={active}
              />
            )}
          </Col>
        </Row>
        <Modal
          type="primary"
          title={t("car.carInformation")}
          visible={state.addVisible}
          footer={null}
          onCancel={handleCancel}
          width={600}
        >
          <div className="project-modal">
            <AddUser>
              <BasicFormWrapper>
                <Form form={form} name="contact" onFinish={handleOk}>
                  <Row gutter={15}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={t("car.licencePlate")}
                        name="licence_plate"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="SPZ" />
                      </Form.Item>
                      <Form.Item
                        name="company"
                        label={t("car.company")}
                        rules={[{ required: true }]}
                      >
                        <SelectCompanyForNewCar data={branchOficcesData} />
                      </Form.Item>

                      <Form.Item
                        name="owner"
                        label={t("car.owner")}
                        rules={[{ required: true }]}
                      >
                        <SelectCarOwner data={carOwnersData} />
                      </Form.Item>

                      <Form.Item
                        name="renter"
                        label={t("car.renter")}
                        rules={[{ required: true }]}
                      >
                        <SelectCarRenter data={carRentersData} />
                      </Form.Item>
                      <Form.Item
                        label={t("car.sendSms")}
                        name="sendSms"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={t("car.driverFirstname")}
                        name="firstname"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>

                      <Form.Item
                        label={t("car.driverLastname")}
                        name="lastname"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label={t("car.driverEmail")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: t("car.validation.wrongEmail"),
                          },
                          {
                            required: true,
                            message: t("car.validation.email"),
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label={t("car.driverPhone")}
                        name="phone"
                        rules={[
                          {
                            autoComplete: "off",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     const pattern = "^[+]421[0-9]{9}$";
                          //     if (value.match(pattern)) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(t("car.validation.phone"));
                          //   },
                          // }),
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="+421xxxxxx" autoComplete="off" />
                      </Form.Item>
                      <Form.Item label={t("car.type")} name="type">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={15}>
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item label={t("car.note")} name="note">
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Col md={24} sm={24} xs={24} style={{ marginTop: "20px" }}>
                      <Button
                        htmlType="submit"
                        size="default"
                        type="primary"
                        key="submit"
                      >
                        {t("car.addCar")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </BasicFormWrapper>
            </AddUser>
          </div>
        </Modal>
      </Main>
    </>
  );
};

export default CarList;
