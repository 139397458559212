import React, { useState, useCallback } from "react";
import { Form } from "antd";
import { Table } from "ant-table-extensions";
import FeatherIcon from "feather-icons-react";
import { UserTableStyleWrapper } from "../../pages/style";
import { TableWrapper } from "../../styled";

import { Button } from "../../../components/buttons/buttons";
import { Cards } from "../../../components/cards/frame/cards-frame";

import { useTranslation } from "react-i18next";

import { ModalForm } from "./tabs/modalForm";

const List = (props) => {
  const { t } = useTranslation("common");
  const { inputData, graphqlVariables } = props;

  const [form] = Form.useForm();

  const [state, setState] = useState({
    editVisible: false,
    update: {},
  });

  const [formReadonly, setFormReadonly] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  const tableData = [];

  inputData &&
    inputData.map((item) => {
      const {
        id,
        company,
        city,
        zip,
        ic,
        ic_dph,
        phone,
        email,
        firstname,
        lastname,
        send_reservation_email,
        note,
      } = item;

      return tableData.push({
        id: id,
        key: id,
        company: company,
        city: city,
        ic: ic,
        icDph: ic_dph,
        phone: phone,
        zip: zip,
        email: email,
        firstname: firstname,
        lastname: lastname,
        note: note,
        send_reservation_email: send_reservation_email,
        action: (
          <div className="table-actions">
            <>
              <Button
                onClick={() => showEditModal(item)}
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="eye" size={16} />
              </Button>
              <Button
                onClick={() => showEditModal(item, "edit")}
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="edit" size={16} />
              </Button>
              {/* <Button
                onClick={() => onHandleDelete(id)}
                className='btn-icon'
                type='danger'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='trash-2' size={16} />
              </Button> */}
            </>
          </div>
        ),
      });
    });

  const showEditModal = (data, type = "view") => {
    setEditFormFieldsValues(data);
    if (type === "edit") {
      setFormReadonly(false);
    } else {
      setFormReadonly(true);
    }
    setState({
      ...state,
      editVisible: true,
      update: data,
    });
    setSelectedItem(data);
  };

  const setEditFormFieldsValues = (data) => {
    const fields = [
      "company",
      "firstname",
      "lastname",
      "street",
      "city",
      "zip",
      "phone",
      "email",
      "ic",
      "dic",
      "ic_dph",
      "send_reservation_email",
      "note",
    ];
    for (const item of fields) {
      updateFormFieldValue(item, data[item]);
    }
  };

  const updateFormFieldValue = useCallback(
    (fieldKey, fieldValue) => {
      form.setFieldsValue({ [fieldKey]: fieldValue });
    },
    [form]
  );

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={tableData}
            columns={getTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: tableData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t("common.from")} ${total} ${t(
                  "common.items"
                )}`,
            }}
            exportableProps={{
              fileName: "zoznam-pobociek",
              btnProps: {
                type: "primary",
                children: <span>Export do CSV</span>,
              },
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      <ModalForm
        t={t}
        tableData={tableData}
        data={props}
        formReadonly={formReadonly}
        form={form}
        selectedItem={selectedItem}
        setState={setState}
        showModal={state.editVisible}
      />
    </Cards>
  );
};

const getTableColumns = (t) => {
  return [
    {
      title: t("carOwners.tableList.id"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("carOwners.tableList.company"),
      dataIndex: "company",
      key: "company",
    },
    {
      title: t("carOwners.tableList.city"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("carOwners.tableList.firstname"),
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: t("carOwners.tableList.lastname"),
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: t("carOwners.tableList.phone"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("common.actions"),
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];
};

export default List;
