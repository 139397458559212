import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Row, Col, Spin } from "antd";

import { Main, CardToolbox } from "../styled";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Button } from "../../components/buttons/buttons";

import { AutoComplete } from "../../components/autoComplete/autoComplete";

import { useTranslation } from "react-i18next";

import { GetBranchOffices } from "./dataQueries";
import {
  setAllBranchOfficces,
  setTableFiler,
} from "../../redux/branchOfficces/actionCreator";

import List from "./overview/list";
import { useDispatch, useSelector } from "react-redux";
import NewRecordModal from "./newRecordModal";

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
};

const BranchOfficesGrid = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [gridState, setGridState] = useState({
    addNewRecord: false,
  });
  const showNewRecordModal = () => {
    setGridState({
      ...gridState,
      addNewRecord: true,
    });
  };
  const onCancel = () => {
    setGridState({
      ...gridState,
      addNewRecord: false,
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const tableData = useSelector(
    (state) => state?.branchOffices?.allBranchOffices
  );
  const listData = useSelector((state) => state?.branchOffices?.tableFilter);

  const [loadData, setLoadData] = useState(false);
  const [loadingList, setLoadingList] = useState(true);

  useEffect(() => {
    setLoadData(true);
  }, []);

  const graphQlData = GetBranchOffices(graphqlVariables, loadData);
  if (graphQlData) {
    const data = graphQlData[0]?.data?.getBranchOficces;
    if (data) {
      const spinnerStatus = graphQlData[0]?.loading;
      dispatch(setAllBranchOfficces(data));
      setLoadingList(spinnerStatus);
      setLoadData(false);
    }
  }

  const handleSearch = (searchText) => {
    const data = tableData.filter((item) => {
      return item.company.toUpperCase().includes(searchText.toUpperCase());
    });
    dispatch(setTableFiler(data));
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Pobočky"
          subTitle={
            <>
              <span className="title-counter">{t("carOwners.filter")} </span>
              <AutoComplete
                onSearch={handleSearch}
                // dataSource={notData}
                placeholder={t("carOwners.startWritting")}
                width="100%"
                patterns
              />
            </>
          }
          buttons={[
            <Button
              onClick={showNewRecordModal}
              className="btn-add_new"
              size="default"
              type="primary"
              key="1"
            >
              {/* <Link to="/admin/users/add-user/info">+ Add New Car</Link> */}
              <Link to="#">{t("common.addRecord")}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingList && (
              <div className="spin">
                <Spin />
              </div>
            )}
            {!loadingList && (
              <List inputData={listData} graphqlVariables={graphqlVariables} />
            )}
          </Col>
        </Row>
      </Main>
      <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        tableData={tableData}
      />
    </>
  );
};

export default BranchOfficesGrid;
