import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import { UserTableStyleWrapper } from "../../../pages/style";
import { TableWrapper } from "../../../styled";
import { setTableTab2Filer } from "../../../../redux/car_owners/actionCreator";

import { useDispatch, useSelector } from "react-redux";

import { setAllCars } from "../../../../redux/cars/actionCreator";
import { AutoComplete } from "../../../../components/autoComplete/autoComplete";

const Tab2 = (props) => {
  const { t, carsData } = props;
  const tableData = useSelector((state) => state.cars?.cars);

  const dispatch = useDispatch();
  const listData = useSelector((state) => state?.carOwners?.tableFilterTab2);

  const carsTableData = [];

  useEffect(() => {
    dispatch(setAllCars(carsData));
    dispatch(setTableTab2Filer(carsData));
  }, [carsData]);

  listData &&
    listData.map((car) => {
      const {
        id,
        driver_firstname,
        driver_lastname,
        licence_plate,
        branchOffice,
        carRenter,
      } = car;
      const company = branchOffice?.company || null;
      const renter = carRenter?.company || null;
      const link = "/admin/car/edit/" + id;
      return carsTableData.push({
        key: id,
        licence_plate: (
          <a key={id} href={link}>
            <span className={`status-text`}>{licence_plate}</span>
          </a>
        ),
        firstname: driver_firstname,
        lastname: driver_lastname,
        company: company,
        renter: renter,
      });
    });

  const handleSearch = (searchText) => {
    const data = tableData.filter((item) => {
      return (
        item.licence_plate.toUpperCase().includes(searchText.toUpperCase()) ||
        item.branchOffice.company
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        item.carRenter.company.toUpperCase().includes(searchText.toUpperCase())
      );
    });
    dispatch(setTableTab2Filer(data));
  };

  return (
    <Cards headless>
      <div style={{ marginBottom: "20px" }}>
        <AutoComplete
          onSearch={handleSearch}
          // dataSource={notData}
          placeholder={t("carOwners.startWritting")}
          width="100%"
          patterns
        />
      </div>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={carsTableData}
            columns={getCarsTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: carsTableData ? carsTableData.length : 0,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t("common.from")} ${total} ${t(
                  "common.items"
                )}`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  );
};
const getCarsTableColumns = (t) => {
  return [
    {
      title: t("car.licencePlate"),
      dataIndex: "licence_plate",
      key: "licence_plate",
    },
    {
      title: t("car.company"),
      dataIndex: "company",
      key: "company",
    },
    {
      title: t("car.renter"),
      dataIndex: "renter",
      key: "renter",
    },
    {
      title: t("car.driverFirstname"),
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: t("car.driverLastname"),
      dataIndex: "lastname",
      key: "lastname",
    },
  ];
};
export { Tab2 };
